.tabs-content .swiper-container {
    width: 100%;
    height: 300px;
  }
  .tabs-content .swiper-slide {
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
  }
  .tabs-buttons {
    height: auto;
    // box-shadow: inset 0 -2px $gray;
  }
  .tabs-buttons .swiper-wrapper {
    display: flex;
    justify-content: center;
  }
  .tabs-buttons .swiper-slide {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: $gray;
    padding: 1rem 2rem;
    border-bottom: 2px solid transparent;
    transition: color 250ms ease-in-out;
    cursor: pointer; 

    span{
        position: relative;
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            background-color: $primary;
            transform: translateY(2px);
            opacity: 0;
            transition: transform 200ms ease-in-out, opacity 150ms ease-in-out;
        }
    }
}
.tabs-buttons .swiper-slide:hover,
.tabs-buttons .swiper-slide-thumb-active {
    color: $primary;
    opacity: 1;
    span:before{
        opacity: 1;
        transform: translateY(0);
    }
  }