html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: $secondary;

  &.noScroll {
    overflow: hidden;
    height: 100%;
  }
}

footer {
  margin-top: auto;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}

/* Links */
a {
  &:hover {
    text-decoration: none;
    color: currentColor;
    transition: color 200ms ease-in-out;
  }
}

/* BUTTON */
.btn {
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  padding: 0.7rem 0.8rem;
  cursor: pointer;
  z-index: 22;

  min-width: 160px;
  color: $black;
  transition: all 0.5s;
  background-color: transparent;
  border: 0;
  border-radius: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 2px solid $black;
    transition: all 0.3s;
    border-radius: 5px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: $dark;
    transition: all 0.3s;
    transform: scale(0.5, 0.5);
    border-radius: 5px;
  }

  &:hover {
    color: $white;
    background-color: transparent;
    &:before {
      opacity: 0;
      transform: scale(1.2, 1.2);
    }

    &:after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  &-secondary {
    color: $white;
    &:before {
      opacity: 1;
      transform: scale(1, 1);
      background-color: $primary;
      z-index: -1;
      border: none;
    }

    &:after {
      opacity: 0;
      transform: scale(1.2, 1.2);
      background-color: transparent;
      border: 2px solid $primary;
    }

    &:hover {
      color: $primary;
      &:before {
        opacity: 0;
        transform: scale(0, 0);
      }
    }
  }

  span {
    z-index: 2;
    position: relative;
  }
}

/* Default */

.default-content {
  &__content {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
