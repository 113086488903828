.hero {
  padding-top: 150px;
  background: $white;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &__container {
    height: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__header {
    width: 592px;
    h1{
      font-weight: 600;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;    
    }
  }

  &__btnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    margin-top: 2rem;
    @media only screen and (max-width: 360px) {
      width: auto;
      flex-direction: column;
      justify-content: center;

      .btn-primary{
        margin-top: 1rem;
      }
    }
  }

  &__imgWrap{
    margin-top: 2rem;
  }
}
