$navBreak: "only screen and (max-width : 991px)";

// HEADER
header {
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 9999;
}

// .headerDark{
//   background: transparent;
// }

// HAMBURGER ICON
.navbar-toggler {
  background: none;
  border: none;
  z-index: 9999;
  &:active,
  &:focus {
    outline: 0;
  }
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
    background-color: $gray;
  }
}

.navbar-toggler {
  .icon-bar {
    &:nth-of-type(1) {
      transform: rotate(0);
    }
    &:nth-of-type(2) {
      opacity: 1;
      filter: alpha(opacity = 100);
      width: 85%;
    }
    &:nth-of-type(3) {
      transform: rotate(0);
      width: 50%;
    }
  }
  &[aria-expanded="true"] .icon-bar {
    &:nth-of-type(1) {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }
    &:nth-of-type(2) {
      opacity: 0;
      filter: alpha(opacity = 0);
      width: 100%;
    }
    &:nth-of-type(3) {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
      width: 100%;
    }
  }
}

/* NAVBAR DEFAULT */

.defaultHeader {
  transition: background-color ease-in-out 250ms, color ease-in-out 250ms;
  padding: 1em 0;
  @media #{$navBreak} {
    padding: 0;

    .navbar-toggler {
      .icon-bar {
        background-color: $secondary;
      }
    }
  }

  .navbar-brand {
    width: 238px;
    height: 52px;
    background-image: url(../img/logo-black.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 4em;
    @media #{$navBreak} {
      width: 119px;
      height: 26;
    }
  }

  .mainNav {
    .navbar-nav {
      .nav-item {
        & + .nav-item {
          margin-left: 1em;
          @media #{$navBreak} {
            margin-left: 0;
          }
        }
        .nav-link {
          color: $secondary;
          position: relative;
          transition: opacity ease-in-out 250ms;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 5px;
            width: calc(100% - 1rem);
            margin-left: 0.5rem;
            height: 2px;
            background: currentColor;

            transform: scaleX(0);
            transform-origin: right;
            transition: transform 250ms ease-in-out;
            @media #{$navBreak} {
              display: none;
            }
          }

          &:hover,
          &:focus,
          &.active {
            opacity: 0.8;
            transition: opacity ease-in-out 250ms;

            &:after {
              transform: scaleX(1);
              transform-origin: left;
              transition: transform 250ms ease-in-out;
            }
          }
        }
      }
    }
  }
}

// NAVBAR SCROLL UP
.header-fade-out {
  box-shadow: 0 0 20px rgba($black, 0.1);
  // animation: slide-bottom 0.5s ease-in-out;
  background-color: $white !important;
  opacity: 1 !important;
  display: block !important;
}
.header-fade-in {
  box-shadow: 0 0 20px rgba($black, 0.1);
  // animation: slide-top 0.5s ease-in-out reverse;
  background-color: $white !important;
  opacity: 1 !important;
  display: block !important;
}

// NAVBAR DARK
.headerDark {
  background-color: transparent;

  .navbar-toggler {
    .icon-bar {
      background-color: $gray;
    }
  }

  .navbar-brand {
    background-image: url(../img/logo-white.svg);
  }

  .mainNav .navbar-nav .nav-item .nav-link {
    color: $gray;
  }
}

// NAVBAR LIGHT
.headerLight {
  background-color: $light-gray;

  .navbar-toggler {
    .icon-bar {
      background-color: $secondary;
    }
  }
  .navbar-brand {
    background-image: url(../img/logo-black.svg);
  }

  .mainNav .navbar-nav .nav-item .nav-link {
    color: $secondary;
  }
}

// NAV BREAK
@media #{$navBreak} {
  #bs4navbar {
    position: fixed;
    top: 68px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $primary;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease-in-out;
  }
  .navBreak {
    background-color: $primary !important;
    // min-height: 100vh;
    // position: relative;
    // overflow: hidden;

    // &:after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 100%;
    //   height: 100vh;
    //   width: 100%;
    //   background-color: $secondary;
    //   transform: skewY(-20deg);
    //   margin-top: -18.5vw;
    // }
    /* BOOTSTRAP OVERRIDE */

    .collapse:not(.show) {
      display: auto;
      opacity: 0;
      height: 100vh;
      transition: opacity 200ms ease-in-out;
    }

    #bs4navbar {
      opacity: 1;
      pointer-events: visible;
    }
    .navbar-brand {
      background-image: url(../img/logo-white.svg);
    }

    .navbar-toggler {
      .icon-bar {
        background-color: $white;
      }
    }
  }

  .defaultHeader {
    .mainNav {
      .navbar-brand {
        margin-right: 0;
      }
      .navbar-nav {
        justify-content: center;
        align-items: center;
        height: 100vh;
        .nav-item {
          .nav-link {
            color: $white;
            font-weight: 800;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

/* USER LOGIN */
.userLogin {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin: 0;

  @media #{$navBreak} {
    width: 53px;
    padding: 0;
  }

  &__link {
    color: $black;
    font-weight: 800;
    position: relative;
    transition: opacity ease-in-out 250ms;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: currentColor;

      transform: scaleX(0);
      transform-origin: right;
      transition: transform 250ms ease-in-out;
      @media #{$navBreak} {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &.active {
      opacity: 0.8;
      transition: opacity ease-in-out 250ms;

      &:after {
        transform: scaleX(1);
        transform-origin: left;
        transition: transform 250ms ease-in-out;
      }
    }

    &--signUp {
      @media #{$navBreak} {
        display: none;
      }
    }
    &--login {
      @media #{$navBreak} {
        color: $primary;
      }
    }

    &--signUp {
      color: $primary;
      &:hover {
        color: $primary;
      }
    }
  }
}

.navBreak {
  .userLogin__link--signUp {
    color: $white;
  }
}
