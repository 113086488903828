.footer {
  &__col {
    &--link {
      text-align: right;
      a + a{
        margin-left: 1em;
      }
      @media only screen and (max-width: 767px) {
        text-align: left;
        margin-top: 1em;
      }
    }
  }

  &__link {
    color: $black;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 0.5px;
      background-color: $black;
      opacity: 0;
      transform: translateY(1px);
      transform-origin: top;
      transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    }
    &:hover {
      color: $black;

      &::after {
        opacity: 1;
        transform: translateY(0);
        transform-origin: bottom;
        transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
      }
    }
  }
}
