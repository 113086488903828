.sub-hero {
    background: $light-gray;
    padding-top: 170px;
    padding-bottom: 90px;
    font-weight: 600;

    h2 {
        font-weight: 600;
        font-size: 50px;
        margin-bottom: 43px;
    }
}

.tabs {
    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &-text {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 28px;
        }
    }

    &__red-dot {
        width: 67px;
        height: 67px;
        border-radius: 50%;
        background-color: $primary;
        margin-bottom: 28px;
    }

    &__text {
        margin-bottom: 28px;
    }

    &:nth-child(odd) {
        background-color: $light-gray;
    }
}
