@keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-10px);
    }
  }
  
  
  /**
   * ----------------------------------------
   * animation slide-top
   * ----------------------------------------
   */
   @keyframes slide-top {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /**
   * ----------------------------------------
   * animation slide-bottom
   * ----------------------------------------
   */
   @keyframes slide-bottom {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }