.blogPosts {
    &__imgWrap {
        min-height: 230px;
        width: 100%;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
    }

    &__text {
        padding: 1em;
    }

    &__title h2 {
        transition: color 250ms ease-in-out;
        font-size: 24px;
    }

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: background-color 0.2s ease-in-out;
    }

    &__content {
        height: 100%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: box-shadow 250ms ease-in-out;
    }

    &__item:hover {
        .blogPosts {
            &__title h2 {
                color: $primary;
            }

            &__content {
                box-shadow: 0 20px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            }
        }
    }
}

/* =================================================
BLOG SINGLE PAGE
================================================= */

.single-banner {
    min-height: 650px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0;
    @media only screen and (max-width: 767px) {
        min-height: 300px;
    }
}

.single-content {
    background-color: $white;
    &__header {
        background-color: $light;
        padding-top: 2em;
        padding-bottom: 2em;
        margin-bottom: 2em;
        @media only screen and (max-width: 576px) {
            h1 {
                font-size: 30px;
            }
        }
    }

    &__content {
        padding: 0 0 2em 0;
    }
}
