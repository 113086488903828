.services {
  background-color: $primary;
  background: linear-gradient(180deg, $primary 80%, $white 20%);
  margin-top: -42px;
  z-index: 1;

  &__content {
    display: flex;
    justify-content: center;
    text-align: center;
    color: $white;
  }

  &__innerContent {
    width: 463px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1em;
    }

    @media only screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

/* BLOG */
.latest-blog {
  position: relative;

  .big-header {
    color: $white;
  }

  &__content {
    padding: 2em;
    position: relative;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: $white;
    border-radius: 12px;
    transition: transform 200ms ease-in-out;
    overflow: hidden;
    // min-height: 563px;
  }

  &__imgbg {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-position-y: -40px;
    background-size: auto 255px;
    opacity: 0.3;
  }

  &__imgWrap {
    padding-bottom: 2em;
    position: relative;
  }

  &__text {
    position: relative;
    small {
      color: $secondary;
    }
    h2 {
      color: $primary;
    }
  }

  &__swiper {
    padding: 2em 0;
    .swiper-pagination {
      bottom: 0;
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }

    .swiper-pagination-bullet{
      background: $primary;
      opacity: 0.5;
      &-active{
        opacity: 1;
      }
    }
  }
}

/* features */

.features {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
  }

  &__colRow{
      justify-content: center;
      align-items: center;
      text-align: center;
      @media only screen and (max-width: 991px) {
          justify-content: space-between;
          padding-top: 2em;
      }
  }

  &__col-col{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2em;
  }

  &__icon{
      width: 100px;
      height: 100px;
      background-color: $light-gray;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
  }

  &__col-colText{
      margin-top: 1em;
      font-weight: 800;
  }
}

/* Support */

.support{
    background-color: $light-gray;

    &__row{
        justify-content: center;
        align-items: center;
        padding-top: 2em;
    }

    &__col{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}